<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="60%"
    top="2vh"
    @close="onDialogClose">
    <div :v-loading="contentLoading" class="body">
      <el-form
        ref="formValidate"
        class="form"
        label-width="110px"
        :model="banner"
        :rules="ruleValidate">
        <el-form-item label="资料类型" prop="contentType">
          <el-select v-model="banner.contentType" style="width: 50%">
            <el-option label="产品资料" :value="10"></el-option>
            <el-option label="视频资料" :value="20"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否展示" prop="showOrder">
          <el-select v-model="banner.showOrder" style="width: 50%">
            <el-option label="展示" :value="10"></el-option>
            <el-option label="不展示" :value="20"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="banner.status" style="width: 50%">
            <el-option label="待发布" :value="10"></el-option>
            <el-option label="发布" :value="20"></el-option>
            <el-option label="作废" :value="99"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择资料">
          <el-select
            v-if="banner.contentType === 10"
            v-model.trim="banner.contentNote"
            placeholder="请选择"
            style="width: 50%"
            filterable
            clearable>
            <el-option
              v-for="item in panList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              @click.native="selectPan(item)">
            </el-option>
          </el-select>
          <el-select
            v-if="banner.contentType === 20"
            v-model.trim="banner.contentNote"
            placeholder="请选择"
            filterable
            style="width: 50%"
            clearable>
            <el-option
              v-for="item in videoList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              @click.native="selectVideo(item)"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="封面">
          <vm-upload-file
            v-if="banner.contentType === 10"
            v-model="banner.coverImage"
            :filename.sync="banner.coverImageNote"
            accept=".img,.png,.jpg">
          </vm-upload-file>
          <span v-else style="cursor: pointer;color: #0747FD;font-size: 14px" @click="openCoverImage()">{{banner.coverImageNote}}</span>
        </el-form-item>
        <el-form-item label="文件" prop="contentUrl">
          <span style="cursor: pointer;color: #0747FD;font-size: 14px" @click="openFile()">{{banner.contentNote}}</span>
          <!--          <vm-upload-file-->
          <!--            v-if="banner.contentType===10"-->
          <!--            v-model="banner.contentUrl"-->
          <!--            :filename.sync="banner.contentNote"-->
          <!--            accept=".pdf,.doc">-->
          <!--          </vm-upload-file>-->
          <!--          <vm-upload-file-->
          <!--            v-else-->
          <!--            v-model="banner.contentUrl"-->
          <!--            :filename.sync="banner.contentNote"-->
          <!--            accept=".mp4">-->
          <!--          </vm-upload-file>-->
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button
        type="primary"
        :loading="submitLoading"
        :disabled="saveDisabled"
        @click="handleSubmit">{{$t("common.save")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  // import FaultSelect from "../fault/FaultSelect.vue";
  import VmUploadFile from "@/components/VmUploadFile";

  const moduleName = "banners";

  export default {
    components: {VmUploadFile},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        saveDisabled: false,
        filter:"",
        banner: {
          id: 0,
          documentId: 0,
          videoId: 0,
          contentType: 10,
          status: 20,
          showOrder: 10,
          coverImage: "",
          coverImageNote: "",
          contentUrl: "",
          contentNote: "",
        },
        panList:[],
        videoList:[],
        ruleValidate: {
          contentNote: [
            {required: true, message: "视频名称不能为空", trigger: "blur"},
          ],
        },
      };
    },
    computed: {
      title() {
        return this.banner.id === 0 ? this.$t("common.add") : this.$t("common.edit");
      },
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        this.banner.id = id;
        if (id > 0) {
          this.getData();
        }
        this.getPan();
        this.getVideo();
      },
      getData() {
        this.$http.get("banners/" + this.banner.id).then(res => {
          console.log(res);
          this.banner = Object.assign(this.banner, res.data);
          this.contentLoading = false;
          this.saveDisabled = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      getPan() {
        let parme = {
          filter:this.filter
        };
        this.$http.get("pans/getAllList",parme).then(res => {
          this.panList = res.data;
        }).catch(() => {

        });
      },
      getVideo() {
        let parme = {
          filter:this.filter
        };
        this.$http.get("videoDatas/getAllList",parme).then(res => {
          this.videoList = res.data;
        }).catch(() => {
          this.$message.error("获取失败");
        });
      },
      selectPan(item) {
        this.banner.contentUrl = item.filePath;
        this.banner.contentNote = item.name;
        this.banner.documentId = item.id;
      },
      selectVideo(item) {
        this.banner.contentUrl = item.filePath;
        this.banner.contentNote = item.name;
        this.banner.videoId = item.id;
        this.banner.coverImage = item.coverPath;
        this.banner.coverImageNote = item.coverName;
      },
      openFile() {
        window.open(this.banner.contentUrl);
      },
      openCoverImage() {
        window.open(this.banner.coverImage);
      },
      onDialogClose() {
        this.banner.contentType = "";
        this.banner.id = 0;
        this.banner.status = "";
        this.banner.showOrder = "";
        this.banner.coverImage = "";
        this.banner.coverImageNote = "";
        this.banner.contentUrl = "";
        this.banner.contentNote = "";
        // this.saveDisabled = false;
        // this.contentLoading = false;
        // this.$refs.formValidate.resetFields();
        // this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
      },
      handleSubmit() {
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            this.$http.save("banners", this.banner).then(res => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success", this.banner.id === 0);
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          } else {
            this.$message.error(this.$t("common.tip.submitError") + "!");
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
